export const NAV_CONF = [
  { to: '/team', text: 'Meet the Team' },
  { to: '/calculator', text: 'Rate Calculator' },
  { to: '/forms', text: 'Forms' },
  { to: '/courses', text: 'Courses' },
  { http: 'https://blog.llabstract.com/', text: 'Blog' },
  { to: '/order', text: 'Order', button: true },
  { to: '/prices', text: 'Prices', footer: true },
  { to: '/contact', text: 'Contact Us', footer: true },
  { to: '/jobs', text: 'Job Opportunities', footer: true },
];
