import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { NAV_CONF } from './nav.config';

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

function MainNav() {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const previousPath = usePrevious<string>(location.pathname);
  useEffect(() => {
    if (
      location.pathname.match(/^\/team/) === null
      || previousPath?.match(/^\/team/) === null
    ) {
      window.scrollTo(0, 0);
      setExpanded(false);
    }
  }, [location.pathname]);

  const headerNav = NAV_CONF.filter((item) => !item.footer);
  return (
    <Navbar
      bg="light"
      expand="lg"
      collapseOnSelect
      expanded={expanded}
      onToggle={() => {
        setExpanded(!expanded);
      }}
    >
      <Container>
        <Link to="/">
          <Navbar.Brand>
            <img src="/images/LLA-logo (line).png" alt="Liberty Land Abstract" />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {headerNav.map((item) => (
              <Link to={item.to || item.http || ''} className="nav-link">
                {item.button ? (
                  <Button className="action">{item.text}</Button>
                ) : item.text}
              </Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNav;
